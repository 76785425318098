
import Vue from "vue";
import appCard from "../card/app-card.vue";
import { openmairieWebsiteUrl } from "@/app.config";
export default Vue.extend({
  components: { appCard },
  name: "appMain",
  data() {
    return {
      cards: [
        {
          id: "12",
          link: "/schedule-app",
          externalLink: false,
          title: "douze",
          iconN: "https://openmairie-static-cdn.azureedge.net/cdn/schedule-manager.svg",
          labelName: "Gestion des rendez-vous",
        },
        {
          id: "13",
          link: "/content-app",
          externalLink: false,
          title: "treize",
          iconN: "https://openmairie-static-cdn.azureedge.net/cdn/content-manager.svg",
          labelName: "Gestion de contenus",
        },
        {
          id: "14",
          link: 'https://golfe3.mairie.tg/', 
          externalLink: true,
          title: "quatorze",
          iconN: "https://openmairie-static-cdn.azureedge.net/cdn/site.svg",
          labelName: "Site Habitant de la commune",
        },
        {
          id: "15",
          link: '/hospi-app', 
          externalLink: false,
          title: "quinze",
          iconN: "https://openmairie.blob.core.windows.net/cdn/hospital-building (1).png",
          labelName: "Hospi Gestion",
        },
        {
          id: "16",
          link: '/service-app',
          externalLink: false,
          title: "seize",
          iconN: "https://openmairie.blob.core.windows.net/cdn/planning.png",
          labelName: "Gestionnaire de services",
        },
      ],
    };
  },
});
