import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import  { RootState } from './types'
import { admin } from './modules/admin/index';
import { layout } from './modules/layout/index';
import { profil } from './modules/profil/index'
import { auth } from './modules/auth';

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0'
    },
    modules: {
        auth: auth,
        admin: admin,
        layout: layout,
        profil: profil
    },
    devtools: false
}

export default new Vuex.Store<RootState>(store)