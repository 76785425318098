
import { Vue } from "vue-property-decorator";
import NavbarApp from '@/components/navbar-app/navbar-app.vue';


export default Vue.extend({
  name: "App",
  components: {
    NavbarApp,
  }

})
