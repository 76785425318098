import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import workingSpace from "../pages/working-space/working-space.vue";
import monProfil from "../components/profil/profil.vue";
import commonVars from "../common/common-var";


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: commonVars.WORKINGSPACE_PATH,
    name: commonVars.HOME,
    component: workingSpace,
  },
  {
    path: commonVars.PROFIL_PATH,
    name: commonVars.PROFIL,
    component: monProfil,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/working-app',
  routes,
});

export default router;