
import { Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import InputForm from "@/components/input-form/input-form.vue";
import AvatarSet from "../avatar/avatar-set/avatar-set.vue";

export default Vue.extend({
  name: "monProfil",
  components: { InputForm, AvatarSet },
  props: {},
  data() {
    return {
      canEdit: false,
      avatarSize: "15rem",
      form: {
        first_name: "",
        last_name: "",
        email: "",
        postal_code: "",
        street: "",
        locality: "",
        region: "",
        phone: "",
        picture: "",
      },
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions("routeRedirect", ["setRouteName"]),
    showPresetData() {
      this.form.last_name = this.getUser.last_name
      this.form.first_name = this.getUser.first_name
      this.form.email = this.getUser.email
      this.form.postal_code = this.getUser.address.postal_code
      this.form.street = this.getUser.address.street_address
      this.form.locality = this.getUser.address.locality
      this.form.region = this.getUser.address.region
      this.form.phone = this.getUser.phone
      this.form.picture = this.getUser.picture
    },
    edit() {
      this.canEdit = false;
      this.setRouteName;
    },
  },
  mounted(){
    this.showPresetData()
  }

});
