
import Vue from "vue";
export default Vue.extend({
  name: "appCard",
  data() {
    return {};
  },
  methods:{
    navigateTo(url: string) {
      if(this.externalLink === false){
        window.history.pushState({}, '', url);
      }else{
        window.open(url, '_blank');
      }
    },
  },
  props: {
    cardObject: {},
    iconName: { type: String, default: "" },
    externalLink: {type: Boolean, default: false},
    applabelname: String,
    link:{
      type: String,
      default:""
    }
  },
});
