
import { Vue } from "vue-property-decorator";
import AppMain from "@/components/app-main/app-main.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: { AppMain },
  name: "workingSpace",
  computed: {
    ...mapGetters("profil", ["getIsProfilVisible"]),
  },


})
