import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ProfilState } from "./types";

export const actions: ActionTree<ProfilState, RootState> = {
  setIsProfilVisible(context, payload: boolean) {
    context.commit("setIsProfilVisible", payload);
  },

};
