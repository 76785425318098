import {Tokens} from "@/plugins/shield/models/token";
import {User} from "@/plugins/shield/models/user";
import utils from "@/plugins/shield/utils/utils";
import store from '@/store';

export class Context {
  private contextTokens: Tokens

  constructor(tokens: any) {
    this.contextTokens = tokens
      store.commit('setUser', this.getUser())
  }

  getExpiresIn():number {
    return this.contextTokens.expires_in
  }

  getContext(): Tokens {
    return this.contextTokens
  }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    getUser(): User {
    const parsedToken = utils.parseJWT(this.contextTokens.idToken)
      if (parsedToken !== null && parsedToken !== "")
        return {
            username: parsedToken.preferred_username,
            first_name: parsedToken.given_name,
            last_name: parsedToken.family_name,
            email: parsedToken.email,
            address: parsedToken.address,
            phone: parsedToken.phone_number,
            picture: parsedToken.picture,
            tenant: parsedToken.tenant,
            tokens: {
                access_token: this.contextTokens.token,
                refresh_token: this.contextTokens.refreshToken,
                id_token: this.contextTokens.idToken
            },
            access: {
                roles: parsedToken.realm_access,
                resource_access: parsedToken.resource_access,
            }
        }
      else {
          return {
              username: "username",
              first_name: "Prénoms ...",
              last_name: "Nom ...",
              email: "E-mail ...",
              address: {
                  street_address: "Rue ...",
                  locality: "Quartier ...",
                  region: "Région ...",
                  postal_code: "Code postal",
              },
              phone: "Téléphone ...",
              picture: "https://openmairie.blob.core.windows.net/user-picture/0386fc4d-35c2-455e-9b28-2a6ccdc177d3.png",
              tenant: "xxx-yyy-zzz",
              tokens: {
                  access_token: this.contextTokens.token,
                  refresh_token: this.contextTokens.refreshToken,
                  id_token: this.contextTokens.idToken
              },
              access: {
                  roles: "Rôles",
                  resource_access: "Accès",
              }
          }
      }
  }

}


