import { ActionTree } from 'vuex';
import { AuthState } from '@/store/modules/auth/types';
import { RootState } from '@/store/types';
import { User } from "@/models/security/user";

export const actions: ActionTree<AuthState, RootState> = {
  setUser(context, payload: User) {
    context.commit('setUser', payload);
  },
};
