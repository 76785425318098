
import Vue from "vue";
export default Vue.extend({
  name: "inputSet",
  data() {
    return {
      image: null as any | File,
    };
  },
  props: {
    value: File,
    defaultSrc: String,
  },
  methods: {
    browse() {
      const input = document.createElement("input");
      input.accept = "image/*";
      input.type = "file";
      input.click();
      input.onchange = () => {
        if (input.files) {
          this.image = input.files[0];
        }
      };
    },
  },
});
