
import Vue from "vue";
export default Vue.extend({
  name: "inputForm",

  props: {
    value: String,
    inputId: String,
    formGroupId: String,
    type: String,
    label: String,
    inputPlaceholder: {
      type: String,
      default: "",
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValue: "",
      name: "",
    };
  },
  methods: {
    sendChange(event: string) {
      this.$emit("input", event);
    },
  },
  watch: {
    value() {
      this.formValue = this.value;
    },
  },
  beforeMount() {
    this.formValue = this.value;
  },
});
